export interface Badge {
  purpose: BadgePurpose;
  status: BadgeStatus;
}

export enum BadgePurpose {
  EMAIL_NOT_VERIFIED,
  NO_ORGANIZATION,
  ORGANIZATION_HAS_USER_REQUESTS,
}

export enum BadgeStatus {
  INACTIVE = 'inactive',
  ERROR = 'error',
  INFO = 'info',
}
