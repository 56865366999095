import { inject, Injectable } from '@angular/core';
import { Badge, BadgePurpose, BadgeStatus } from './badge.models';
import { AuthService } from '../../../core/services/auth.service';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * todo this should probably be in store
 */
export class BadgeService {
  private authService = inject(AuthService);
  private badges$ = new BehaviorSubject<Badge[]>([]);

  constructor() {
    this.authService.emailVerified.subscribe((emailVerified) => {
      this.setStatus({
        purpose: BadgePurpose.EMAIL_NOT_VERIFIED,
        status: !emailVerified ? BadgeStatus.INFO : BadgeStatus.INACTIVE,
      });
    });
  }

  /**
   * This basically defines the prio of the badge status, if there are multiple purposes on one icon
   * @param badgePurposes
   */
  getPrioritizedStatus(badgePurposes: BadgePurpose[]): Observable<BadgeStatus> {
    return this.badges$.asObservable().pipe(
      map((badges) => {
        const filteredBadgeStatus = badges
          .filter((badge) => badgePurposes.includes(badge.purpose))
          .flatMap((badge) => badge.status);

        if (filteredBadgeStatus.includes(BadgeStatus.ERROR)) {
          return BadgeStatus.ERROR;
        }
        if (filteredBadgeStatus.includes(BadgeStatus.INFO)) {
          return BadgeStatus.INFO;
        }
        return BadgeStatus.INACTIVE;
      }),
    );
  }

  setStatus(badge: Badge) {
    // Get the current array of badges
    const currentBadges = this.badges$.value;

    // Find the index of the badge in the array by its purpose
    const index = currentBadges.findIndex((b) => b.purpose === badge.purpose);

    if (index !== -1) {
      // Badge found, update the existing badge's status
      currentBadges[index].status = badge.status;
    } else {
      // Badge not found, add the new badge
      currentBadges.push(badge);
    }

    // Update the observable's value
    this.badges$.next(currentBadges);
  }
}
